import React from "react";
import Select from "react-select";
import SelectControl from "components/selectControl/SelectControl";
import { fetchSupportedBanks } from "redux/actions/payoutsActions";
import { useDispatch, useSelector } from "react-redux";

const InternationalPaymentOne = ({
  formValues,
  countryOptions,
  onChange,
  errors,
  bankOptions
}) => {
  const dispatch = useDispatch();
  const {
    loadingBanks,
   } = useSelector(({ payouts }) => payouts);

 console.log({bankOptions})
  const handleChange = (e) => {
    onChange(e, "stepOne");
  };

  return (
    <>
      <div className="mb-4">
        <label htmlFor="country">Destination Country</label>
        <Select
          placeholder="Select Country" 
          isLoading={false}
          onChange={(e) => {
            dispatch(fetchSupportedBanks({channel: "Bank", country: e.value}));
            onChange({ target: { ...e, name: "country" } }, "stepOne");
          }}
          name="country"
          controlClassName={`form-control ${
            errors?.country?.message ? "is-invalid" : ""
          } ps-0 pt-0 pb-0`}
          controlErrorMsg={errors?.country?.message}
          components={{
            IndicatorSeparator: () => null,
            Control: SelectControl,
          }}
          defaultValue={countryOptions.find(
            (v) => v.value === formValues.stepOne.country
          )}
          options={countryOptions}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="BankName">Bank Name</label>
        <input
          type="text"
          name="BankName"
          placeholder="Enter Bank Name"
          value={formValues.stepOne.BankName}
          onChange={(e) => {
            onChange({ target: e.target }, "stepOne");
      }}
          className={"form-control"}
        />
        {/* <Select
                  isClearable
                  isSearchable
                  inputId="institutionCode"
                  isLoading={loadingBanks}
                  placeholder=""
                  onChange={(e) => {
                        onChange({ target: { ...e, name: "BankName" } }, "stepOne");
                  }}
                 controlClassName={`form-control ${
                    errors?.institutionCode?.message ? "is-invalid" : ""
                  } ps-0 pt-0 pb-0`}
                  controlErrorMsg={errors?.institutionCode?.message}
                  components={{
                    IndicatorSeparator: () => null,
                    Control: SelectControl,
                  }}
                  options={bankOptions}
                /> */}
      </div>

      <div className="mb-4">
        <label htmlFor="BankAddress">Bank Address</label>
        <input
          type="text"
          name="BankAddress"
          placeholder="e.g John Street"
          value={formValues.stepOne.BankAddress}
          onChange={handleChange}
          className={"form-control"}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="RoutingNumber">Routing Number</label>
        <input
          type="text"
          name="RoutingNumber"
          placeholder="e.g 0123456789"
          value={formValues.stepOne.RoutingNumber}
          onChange={handleChange}
          className={"form-control"}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="SwiftCode">Swift Code</label>
        <input
          type="text"
          name="SwiftCode"
          placeholder="e.g 14-04-50"
          value={formValues.stepOne.SwiftCode}
          onChange={handleChange}
          className={"form-control"}
        />
      </div>
    </>
  );
};

export default React.memo(InternationalPaymentOne);
