import { Get, Post, Put } from "../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";
import {
  accountServiceApiUrl,
  serviceManager,
} from "apiServices/config.service";

export const payoutsActionTypes = {
  CHECK_MERCHANT_STATUS: "[PAYOUTS] CHECK_MERCHANT_STATUS",
  GET_SUPPORTED_COUNTRIES: "[PAYOUTS] GET_SUPPORTED_COUNTRIES",
  GET_CURRENCIES: "[PAYOUTS] GET_CURRENCIES",
  GET_TRANSACTIONS_HISTORY: "[PAYOUTS] GET_TRANSACTIONS_HISTORY",
  GET_CHART_DATA: "[PAYOUTS] GET_CHART_DATA",
  TOGGLE_STATUS: "[PAYOUTS] TOGGLE_STATUS",
  REQUEST_ACCESS: "[PAYOUTS] REQUEST_ACCESS",
  UPDATE_SETTINGS_DATA: "[PAYOUTS] UPDATE_SETTINGS_DATA",
  GET_SETTINGS_DATA: "[PAYOUTS] GET_SETTINGS_DATA",
  GET_SUPPORTED_BANKS: "[PAYOUTS] GET_SUPPORTED_BANKS",
  GET_SUPPORTED_MOBILE_PROVIDERS: "[PAYOUTS] GET_SUPPORTED_MOBILE_PROVIDERS",
  GET_SUPPORTED_WALLETS: "[PAYOUTS] GET_SUPPORTED_WALLETS",
  FETCH_FORM_CONFIG: "[PAYOUTS] FETCH_FORM_CONFIG",
  MAKE_TRANSFER: "[PAYOUTS] MAKE_TRANSFER",
  GET_SUPPORTED_CURRENCIES: "[PAYOUTS] GET_SUPPORTED_CURRENCIES",
  RESOLVE_BANK_ACCOUNT: "[PAYOUTS] RESOLVE_BANK_ACCOUNT",
  WEBHOOK_NOTIFICATION: "[PAYOUTS] WEBHOOK_NOTIFICATION",
};

export const checkMerchantStatus = createAsyncAction(
  payoutsActionTypes.CHECK_MERCHANT_STATUS,
  async () => {
    return await Post(`client/checkaccess`, {});
  }
);

export const requestAccess = createAsyncAction(
  payoutsActionTypes.REQUEST_ACCESS,
  async () => {
    return await Post(`client/requestaccess`, {});
  }
);

export const fetchSupportedCountries = createAsyncAction(
  payoutsActionTypes.GET_SUPPORTED_COUNTRIES,
  async () => {
    return await Get(`client/country/getlist?pageSize=400&pageNumber=1`);
  }
);

export const fetchSupportedCurrencies = createAsyncAction(
  payoutsActionTypes.GET_SUPPORTED_CURRENCIES,
  async () => {
    return await Get(
      `Currency/wallet?pageSize=400&pageNumber=1`,
      serviceManager
    );
  }
);

export const fetchServiceManagerCurrencies = createAsyncAction(
  payoutsActionTypes.GET_CURRENCIES,
  async () => {
    return await Get(
      "Currency/GetAll?pageSize=1000&pageNumber=1",
      serviceManager
    );
  }
);

export const fetchTransactionsHistory = createAsyncAction(
  payoutsActionTypes.GET_TRANSACTIONS_HISTORY,
  async ({
    pageNumber,
    pageSize,
    search,
    currency,
    startDate,
    endDate,
    status,
  }) => {
    return await Get(
      `client/transactions?PageNumber=${pageNumber}&pageSize=${pageSize}${
        search ? "&Keyword=" + search : ""
      }${startDate ? "&StartDate=" + startDate : ""}${
        endDate ? "&EndDate=" + endDate : ""
      }${currency ? "&Currency=" + currency : ""}${
        status && status !== "All" ? "&Status=" + status : ""
      }`
    );
  }
);

export const fetchChartData = createAsyncAction(
  payoutsActionTypes.GET_CHART_DATA,
  async ({ currency, startDate, endDate, search, status }) => {
    return await Get(
      `client/dailytransactions?Currency=${currency}${
        startDate ? "&StartDate=" + startDate : ""
      }${endDate ? "&EndDate=" + endDate : ""}${
        search ? "&Search=" + search : ""
      }`
    );
  }
);

export const updateSettingsData = createAsyncAction(
  payoutsActionTypes.UPDATE_SETTINGS_DATA,
  async (data) => {
    return await Put(`client/settings`, { ...data });
  }
);

export const fetchSettingsData = createAsyncAction(
  payoutsActionTypes.GET_SETTINGS_DATA,
  async () => {
    return await Get("client/settings");
  }
);

export const fetchSupportedBanks = createAsyncAction(
  payoutsActionTypes.GET_SUPPORTED_BANKS,
  async ({ channel, country }) => {
    return await Get(`client/institutions/${country}?channel=${channel}`);
  }
);

export const resolveBankAccount = createAsyncAction(
  payoutsActionTypes.RESOLVE_BANK_ACCOUNT,
  async (accountNumber, institutionCode, countryCode) => {
    return await Get(
      `client/resolve-bank-account?AccountNumber=${
        accountNumber || ""
      }&institutionCode=${institutionCode || ""}&countryCode=${
        countryCode || "NGA"
      }`
    );
  }
);

export const fetchAccountServiceWallets = createAsyncAction(
  payoutsActionTypes.GET_SUPPORTED_WALLETS,
  async () => {
    return await Get("corporateWallet/wallets/client", accountServiceApiUrl);
  }
);

export const fetchSupportedMobileProviders = createAsyncAction(
  payoutsActionTypes.GET_SUPPORTED_MOBILE_PROVIDERS,
  async () => {
    return await Get("client/settings");
  }
);

export const fetchFormConfig = createAsyncAction(
  payoutsActionTypes.FETCH_FORM_CONFIG,
  async ({ country, channel }) => {
    return await Get(`disbursement/gateway/${country}/${channel}`);
  }
);

export const makeTransfer = createAsyncAction(
  payoutsActionTypes.MAKE_TRANSFER,
  async ({ payload, channel }) => {
    return await Post("disbursement/pay", { ...payload, channel });
  }
);

export const webhookNotification = createAsyncAction(
  payoutsActionTypes.WEBHOOK_NOTIFICATION,
  async (reference) => {
    return await Post(`client/notification`, {
      transactionReference: [reference],
    });
  }
);
