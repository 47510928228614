import React from "react";
import { Card, CardBody } from "reactstrap";
import Button from "vgg-ui-react/dist/components/Core/Button";
import "./components.scss";

const data = [
  {
    key: "bank",
    title: "Send to Bank",
  },
  {
    key: "mobileMoney",
    title: "Mobile Money",
  },
  {
    key: "internationalPayment",
    title: "International Payment",
  },
];

const Initiate = ({ onSelectPayoutType, onClose }) => {
  return (
    <div className="fade_in">
      <div className="row mb-2">
        {data.map((v) => (
          <div key={v.key} className="col-12 mb-3">
            <Card
              onClick={() => onSelectPayoutType(v.key)}
              className="card-wrapper"
            >
              <CardBody>
                <div className="d-flex align-items-center justify-content-between">
                  <div>{v.title}</div>
                  <div>
                    <i className="ri-arrow-right-s-line .arrow"></i>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        ))}
      </div>
      <div className="row payout-modal__footer fade_in">
        <div className="col-12">
          <Button
            className="w-100"
            label="Close"
            background="blue"
            size="md"
            style={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
            }}
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default Initiate;
