export const URLS = {
  Home: "/",
  Configure: "/app/payout/configure",
  ConfigureUpdate: "/app/payout/configure/:id",
  Configuration: "/app/payout/configuration/:id",
  Overview: "/app/payout/overview",
  Lookup: "/app/payout/lookup",
  Settings: "/app/payout/settings",
};

export const ConfigureUpdate = id => {
  return URLS.ConfigureUpdate.replace(":id", id);
};

export const ViewConfiguration = id => {
  return URLS.Configuration.replace(":id", id);
};

export default URLS;
