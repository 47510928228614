import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import "./filterDropdown.scss";

const FilterDropdown = ({
  items,
  onItemClick,
  onToggle,
  children,
  buttonContent,
  showFilterIcon,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown
      toggle={() => {
        setIsOpen(!isOpen);
        onToggle && onToggle({ isOpen });
      }}
      isOpen={isOpen}
    >
      <DropdownToggle
        data-testid="dropdown-toggle"
        className="toggle-btn justify-content-center"
      >
        {showFilterIcon && <i className="ri-filter-3-line mr-2"></i>}
        {buttonContent ? (
          <>{buttonContent}</>
        ) : (
          <>
            <i data-testid="filter-icon" className="ri-filter-3-line"></i>&nbsp;
            Filter
          </>
        )}{" "}
        &nbsp;
        <i className={`ri-arrow-drop-${isOpen ? "up" : "down"}-line`}></i>
      </DropdownToggle>
      {children && (
        <DropdownMenu container="body" end>
          {children}
        </DropdownMenu>
      )}
      {items && !children && (
        <DropdownMenu className="mh-200" container="body" end>
          {items.map((item) => (
            <DropdownItem
              key={item}
              onClick={() => onItemClick && onItemClick(item)}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </Dropdown>
  );
};

export default FilterDropdown;
