import React from "react";

const InternationalPaymentTwo = ({ formValues, onChange, errors }) => {
  const handleChange = (e) => {
    onChange(e, "stepTwo");
  };
  return (
    <>
      <div className="mb-4 form-group">
        <label htmlFor="BeneficiaryName">Beneficiary Name</label>
        <input
          type="text"
          name="BeneficiaryName"
          placeholder="e.g John Doe"
          value={formValues.stepTwo.BeneficiaryName}
          onChange={handleChange}
          className={"form-control"}
        />
      </div>

      <div className="mb-4 form-group">
        <label htmlFor="BeneficiaryAddress">Beneficiary Address</label>
        <input
          type="text"
          name="BeneficiaryAddress"
          placeholder="e.g John Street"
          value={formValues.stepTwo.BeneficiaryAddress}
          onChange={handleChange}
          className={"form-control"}
        />
      </div>

      <div className="mb-4 form-group">
        <label htmlFor="destinationAccount">Account Number/IBAN</label>
        <input
          type="text"
          name="destinationAccount"
          placeholder="e.g 09323213"
          value={formValues.stepTwo.destinationAccount}
          onChange={handleChange}
          className={"form-control"}
        />
      </div>
      <div className="mb-4 form-group">
        <label htmlFor="destinationAmount">Amount</label>
        <input
          type="text"
          name="destinationAmount"
          placeholder="e.g $200"
          value={formValues.stepTwo.destinationAmount}
          onChange={handleChange}
          className={"form-control"}
        />
      </div>
      {/* <div className="mb-4 form-group">
        <label htmlFor="TransactionReference">Reference</label>
        <input
          type="text"
          name="TransactionReference"
          placeholder="e.g payment"
          value={formValues.stepTwo.TransactionReference}
          onChange={handleChange}
          className={"form-control"}
        />
      </div> */}

      <div className="mb-4 form-group">
        <label htmlFor="PaymentPurpose">Purpose of payment</label>
        <input
          type="text"
          name="PaymentPurpose"
          placeholder="e.g School"
          value={formValues.stepTwo.PaymentPurpose}
          onChange={handleChange}
          className={"form-control"}
        />
      </div>
    </>
  );
};

export default React.memo(InternationalPaymentTwo);
