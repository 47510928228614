import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ServiceDetails from "pages/ServiceDetails/ServiceDetails";
import { checkMerchantStatus } from "redux/actions/payoutsActions";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import CustomSpinner from "shared/CustomSpinner/CustomSpinner";
import OnboardingPage from "pages/OnboardingPage/OnboardingPage";
import { errorMessage, successMessage } from "redux/actions/notification";

const ManagerPage = () => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const [alreadyOnboarded, setAlreadyOnboarded] = useState(true);


  const { checkingMerchantStatus, merchantStatus, merchantStatusError } = useSelector(({ payouts }) => payouts);

  useEffect(() => {
    dispatch(checkMerchantStatus());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!checkingMerchantStatus && merchantStatus && checked) {
      setChecked(false);
      !alreadyOnboarded &&
        dispatch(
          successMessage(
            merchantStatus?.data?.responseMessage || `Verification complete. `
          )
        );
    }
    if (!checkingMerchantStatus && merchantStatusError && checked) {
      setChecked(false);
      dispatch(
        errorMessage(
          merchantStatusError?.message ||
          "Unable to verify your onboarding status on this service"
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkingMerchantStatus, merchantStatusError, merchantStatus]);

  const renderConditionalContent = (onboarded, active) => (onboarded && active ? <ServiceDetails /> : <OnboardingPage isOnboarded={onboarded} onOnboardClicked={() => setAlreadyOnboarded(false)} />);

  if (checkingMerchantStatus && !merchantStatus) {
    return <CustomSpinner height="75vh" />;
  }

  if (merchantStatus?.data && merchantStatus?.data?.requestSuccessful) {
    return renderConditionalContent(merchantStatus?.data?.responseData?.isOnboarded, merchantStatus?.data?.responseData?.isOnboardedAndActive);
  }
  if (merchantStatus?.data && !merchantStatus?.data?.requestSuccessful) {
    return <ErrorPage />;
  }
  return null;
  // return <OnboardingPage />;
};

export default ManagerPage;
