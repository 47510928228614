import { setUserDetails } from "apiServices/user.service";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { store } from "redux/store";
import App from "./App";
// import "react-phone-input-2/lib/bootstrap.css";
import "react-phone-number-input/style.css";
import "./index.scss";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://e3fba596a3a840bc95937edfdcbbc520@o4504252533964800.ingest.sentry.io/4504252538355712",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

window.renderPayoutServiceApp = ({ containerId, history, userDetail, url }) => {
  setUserDetails({ ...userDetail });
  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <App url={url} />
        </Router>
      </Suspense>
    </Provider>,
    document.getElementById(containerId)
  );
};

// unmount micro frontend function
window.unmountPayoutServiceApp = ({ containerId }) => {
  if (document.getElementById(containerId)) {
    ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
  }
};

// Mount to root if it is not a micro frontend
if (!document.getElementById("PayoutServiceApp-container")) {
  if (document.getElementById("root")) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            <App baseRoute="/" />
          </Router>
        </Suspense>
      </Provider>,
      document.getElementById("root")
    );
  }
}
