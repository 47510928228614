import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Spinner,
} from "reactstrap";
import { webhookNotification } from "../../../../redux/actions/payoutsActions";

import "./webhookNotification.scss";
import Notification from "../../../../shared/notifications/notifications";
import { WebhookIcon } from "../../../../shared/notifications/icons";
import { errorMessage, successMessage } from "../../../../redux/actions/notification";

const WebhookNotification = ({ 
  reference,
  show,
  toggleModal
}) => {
  const dispatch = useDispatch();
  const [submitStatus, setSubmitStatus] = useState(false);
  
  const { webhookNotificationSuccess,webhookNotificationError, webhookNotificationLoading} = useSelector(({ payouts }) => {
    return {
      webhookNotificationSuccess: payouts?.webhookNotificationSuccess,
      webhookNotificationLoading: payouts?.webhookNotificationLoading,
      webhookNotificationError: payouts?.getTransactionError,
    };
  });
 
  const onSubmit = () => {
    setSubmitStatus(true);
      dispatch(webhookNotification(reference))
   }

  useEffect(()=>{
    if(!webhookNotificationLoading && webhookNotificationSuccess?.data?.requestSuccessful && submitStatus) {
      toggleModal();
    setSubmitStatus(false);
      dispatch(successMessage (webhookNotificationSuccess?.data?.message|| "Webhook notification was successful"))
    }else if(!webhookNotificationLoading && webhookNotificationSuccess?.data?.requestSuccessful === false  && submitStatus) {
    setSubmitStatus(false);
    dispatch(errorMessage(webhookNotificationSuccess?.data?.message||"Error occurred while performing this action"))
    }else if(!webhookNotificationLoading && webhookNotificationError  && submitStatus) {
    setSubmitStatus(false);
    dispatch(errorMessage(webhookNotificationError?.data?.message || "Error occurred while performing this action"))
    }
  }, [webhookNotificationLoading])
  return (
    <Modal
      isOpen={!!show}
      toggle={toggleModal}
      centered
      size="md"
      className="webhook-notification-modal-container"
    >
              <Notification />
      <ModalHeader toggle={toggleModal}>
          <span className="renotify">Renotify</span>
      </ModalHeader>
      <ModalBody className="modal__body">
              <div className="content">
                <div className="web-icon"><WebhookIcon /></div>
                <div className="title">Resend webhook notification</div>
                <div className="description">
                Do you want to resend a webhook notification for this transaction? Ensure that this is handled well to avoid duplicate transactions on your system.
                </div>
              </div>
           
             <div>
             <Button
          color="primary"
          onClick={onSubmit}
          className="submit-btn"
          disabled={webhookNotificationLoading}
        >
          {!webhookNotificationLoading?"Renotify":<Spinner size="sm"/>}
        </Button>
             </div>
             <div>
             <Button
          onClick={toggleModal}
          className="cancel-btn"
         >
         Cancel
        </Button>
             </div>
      </ModalBody>
      </Modal>
  );
};

export default WebhookNotification;
