import React, { useState } from "react";
import EmptyState from "components/emptyState/EmptyState";
import { LoadingTableContent } from "components/loader/Loader";
import ViewModal from "./viewModal/ViewModal";
import { Pagination } from "vgg-ui-react/dist/components/Core/Pagination";
import PaginationIndicator from "vgg-ui-react/dist/components/Core/Pagination/PaginationIndicator";
import PaginationWrapper from "vgg-ui-react/dist/components/Core/Pagination/PaginationWrapper";
import Table from "vgg-ui-react/dist/components/Core/Table";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import "./transactionsHistory.scss";
import { MoreIconIcon } from "shared/notifications/icons";
import WebhookNotification from "./webhookNotification/webhookNotification";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";

const TransactionsHistory = ({
  payload,
  loading,
  onPageChange,
  handleSearch,
  page,
  onStatusSelect,
  statuses,
  selectedStatus,
}) => {
  const [openView, setOpenView] = useState(false);
  const [data, setData] = useState(false);
  const [viewWebhook, setViewWebhook] = useState(false);
  const [reference, setReference] = useState("");
  const [show, setShow] = useState(0);

  const toggleView = (detail) => {
    setOpenView(!openView);
    if (detail) {
      setData(detail);
    }
  };
  return (
    <div className=" sp-mt--16 transaction-wrapper">
      <div className="d-flex align-items-center justify-content-between">
        <div className="w-100">
          <Typography
            typographyVariant="section-title"
            className="title-text"
            text="Transaction History"
          />
          <div className="w-100 d-flex align-items-center justify-content-between pr-2">
            <div className="search-wrapper">
              <div>
                <input
                  type="text"
                  placeholder="search"
                  className="form-control"
                  onChange={handleSearch}
                />
                <i className="ri-search-line"></i>
              </div>
            </div>
            <FilterDropdown
              showFilterIcon
              buttonContent={selectedStatus}
              items={statuses}
              onItemClick={onStatusSelect}
            />
          </div>
        </div>
      </div>
      <div className="sp-pb--32 w-100 d-flex flex-column align-items-center justify-content-between px-2">
        <Table cellVariants="default" className="table-striped">
          <thead>
            <tr>
              <th width="20%">Recepient Name</th>
              <th width="20%">Acc Number</th>
              <th width="10%">Source Currency</th>
              <th width="10%">Dest. Currency</th>
              <th width="20%">Amount</th>
              <th width="10%">Status</th>
              <th width="10%"></th>
            </tr>
          </thead>
          {loading ? (
            <tbody className="fade_in">
              <LoadingTableContent colSpan={7} />
            </tbody>
          ) : (
            <tbody className="fade_in">
              {payload?.responseData?.items &&
                payload?.responseData?.items?.map((val) => (
                  <tr key={val?.transactionReference}>
                    <td>
                      <span className="fw-normal">
                        {val?.recepientName || "--"}
                      </span>
                    </td>
                    <td>{val?.benficiaryAccount || "--"}</td>
                    <td>{val?.sourceCurrency || "--"}</td>
                    <td>{val?.destinationCurrency || "--"}</td>
                    <td>{val?.amount || "--"}</td>

                    <td>
                      <span className="ms-2">{val?.status}</span>
                    </td>
                    <td>
                      <div
                        className="options"
                        onMouseLeave={() => setShow(0)}
                        onMouseEnter={() => setShow(val.transactionReference)}
                      >
                        <MoreIconIcon />
                        {show === val.transactionReference && (
                          <div className="more-options">
                            <div
                              className="option"
                              onClick={() => {
                                setViewWebhook(true);
                                setReference(val?.transactionReference);
                              }}
                            >
                              Renotify
                            </div>
                            <div
                              className="view-payout-detail"
                              onClick={() => toggleView(val)}
                            >
                              View
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
        {!loading &&
        (payload?.responseData?.items?.length < 1 ||
          !payload?.responseData?.items) ? (
          <EmptyState />
        ) : undefined}
        {payload?.responseData?.items &&
          payload?.responseData?.items?.length > 0 && (
            <div className="my-3 w-100">
              <PaginationWrapper>
                <PaginationIndicator
                  currentPage={payload?.responseData?.pageNumber || 1}
                  totalPages={payload?.responseData?.pages || 1}
                />
                <div className="pagination-container no-pb">
                  <Pagination
                    className="pagination-bar"
                    currentPage={page?.pageNumber}
                    totalCount={payload?.responseData?.count}
                    pageSize={page?.pageSize}
                    onPageChange={(page) => onPageChange(page)}
                  />
                </div>
              </PaginationWrapper>
            </div>
          )}
      </div>
      <ViewModal openView={openView} toggle={toggleView} data={data} />
      <WebhookNotification
        reference={reference}
        show={viewWebhook}
        toggleModal={() => setViewWebhook(!viewWebhook)}
      />
    </div>
  );
};

export default TransactionsHistory;
