import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { errorMessage, successMessage } from "redux/actions/notification";
import { fetchSupportedCurrencies, fetchSettingsData, updateSettingsData } from "redux/actions/payoutsActions";
import CustomSpinner from "shared/CustomSpinner/CustomSpinner";
import Button from "vgg-ui-react/dist/components/Core/Button";

const Settings = () => {
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const dispatch = useDispatch();
  const { updatingSettingsData, updateSettingsDataPayload, updateSettingsDataError, loadingSettingsData, settingsData, supportedCurrencies, loadingSupportedCurrencies } = useSelector(({ payouts }) => payouts);

  useEffect(() => {
    dispatch(fetchSettingsData());
    dispatch(fetchSupportedCurrencies());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!updatingSettingsData && updateSettingsDataPayload && submitStatus) {
      setSubmitStatus(false);
      setShowForm(false);
      dispatch(successMessage("Successfully updated data"));
      dispatch(fetchSettingsData());
    }
    if (!updatingSettingsData && updateSettingsDataError && submitStatus) {
      setSubmitStatus(false);
      dispatch(errorMessage(updateSettingsDataError?.message || "Unable to update data"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSettingsDataPayload, updatingSettingsData]);

  useEffect(() => {
    if (settingsData?.responseData && supportedCurrencies?.items) {
      const currency = supportedCurrencies?.items?.find(currency => currency.currencyCode === settingsData?.responseData?.currency);
      if (currency) {
        const value = currency.currencyCode;
        const label = currency.name;
        setSelectedCurrency({ label, value });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsData, supportedCurrencies]);

  const submit = () => {
    if (selectedCurrency) {
      dispatch(updateSettingsData({ currency: selectedCurrency.value }));
      setSubmitStatus(true);
    }
  };

  return (
    <div className="row fade_in">
      <div className="col-12 col-md-4">
        <p className="fs-5 fw-bold">Preferences</p>
        {loadingSettingsData ? (
          <CustomSpinner height="5vh" />
        ) : (
          <div className="row align-items-start align-items-md-center fade_in">
            {showForm ? (
              <>
                <div className="col-12 col-md-8 mb-3 mb-md-0 fade_in">
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder="Select Currency"
                    isSearchable
                    isLoading={loadingSupportedCurrencies}
                    value={selectedCurrency}
                    options={supportedCurrencies?.items.map(currency => ({ label: currency.name, value: currency.currencyCode }))}
                    onChange={e => setSelectedCurrency(e)}
                  />
                </div>
                <div className="col-12 col-md-4 fade_in">
                  <Button label={updatingSettingsData ? "Saving..." : "Save"} disabled={!selectedCurrency || updatingSettingsData} background="blue" onClick={() => submit()} />
                </div>
              </>
            ) : (
              <div className="col-12 fade_in">
                <p className="text-secondary">
                  Your default currency is <span className={settingsData?.responseData?.currenc ? "font-weight-bold" : ""}>{settingsData?.responseData?.currency || "not yet set"}</span>
                </p>
                <Button label={settingsData?.responseData?.currency ? "Change" : "Setup"} background="blue" onClick={() => setShowForm(true)} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
