import React, { useEffect } from "react";
import "./service-details.scss";
import CustomTab from "components/CustomTab/CustomTab";
import URLS from "helpers/urls";
import Overview from "pages/Overview/Overview";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Settings from "pages/Settings/Settings";

const tabItems = [
  {
    id: 1,
    title: "Overview",
    key: "overview",
    routeLink: URLS.Overview,
    component: Overview,
  },
  {
    id: 2,
    title: "Settings",
    key: "settings",
    routeLink: URLS.Settings,
    component: Settings,
  },
];

const ServiceDetails = () => {
  const history = useHistory();

  useEffect(() => {
    if(history.location.pathname ==="/payout-service") {
      history.push(URLS.Overview);
    }else{
      const path = history.location.pathname.split('/');
      const index = path.indexOf("app");
      const urlPath = path.splice(index, path.length)
      history.push(`/${urlPath.join('/')}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="service-details fade_in">
      <section className="head-section d-flex">
        <div
          className="head-section__icon d-flex justify-content-center align-items-center"
          onClick={() => {
            window.location.replace("/products");
          }}
        >
          <i className="ri-arrow-drop-left-line"></i> Payout
        </div>
      </section>

      <section className="content-section sp-pt--24" data-testid="content-section">
        <CustomTab data={tabItems}>
          <Switch>
            {tabItems.map(item => (
              <Route key={item.key} exact path={item.routeLink} component={item.component} />
            ))}
          </Switch>
        </CustomTab>
      </section>
    </div>
  );
};

export default ServiceDetails;
