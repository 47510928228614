import React from "react";
import { getCurrencyValue } from "helpers/currencyMarkup";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./ViewModal.scss";

const ViewModal = ({
  openView,
  toggle,
  data,
 }) => {
  return (
    <Modal
      isOpen={openView}
      toggle={toggle}
      className="drawer fadeInRight w-500 animated ml-auto view-payout-modal-container"
    >
      <ModalHeader toggle={toggle} className="item-head">
        <div className="title">Transaction Details</div>
      </ModalHeader>
      <ModalBody>
        <div>
          <div className="item-title">Recepient Name</div>
          <div className="item-value">{data?.recepientName||"--"}</div>
        </div>
        <div>
          <div className="item-title">Acc. Number</div>
          <div className="item-value">{data?.benficiaryAccount}</div>
        </div>
        <div>
          <div className="item-title">Source Currency</div>
          <div className="item-value">{data?.sourceCurrency}</div>
        </div>
        <div>
          <div className="item-title">Destination Currency</div>
          <div className="item-value">{data?.destinationCurrency}</div>
        </div>
        <div>
          <div className="item-title">Amount</div>
          <div className="item-value">{data?.amount}</div>
        </div>
        <div>
          <div className="item-title">Reference</div>
          <div className="item-value">{data?.transactionReference}</div>
        </div>
        <div>
          <div className="item-title">Narration</div>
          <div className="item-value">{data?.narration}</div>
        </div>
        {data?.transferFee && <div>
          <div className="item-title">Transfer Fee</div>
          <div className="item-value">{data?.transferFee}</div>
        </div>}
        <div>
          <div className="item-title">Status</div>
          <div className="item-value">{data?.status}</div>
        </div>
        <div>
          <div className="item-title">Date</div>
          <div className="item-value">{data?.transactionDate}</div>
        </div>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default ViewModal;
