import { getUserDetails } from "apiServices/user.service";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import {
  checkMerchantStatus,
  requestAccess,
} from "redux/actions/payoutsActions";
import "./onboardingPage.scss";
import AlertModal from "components/AlertModal/AlertModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

function OnboardingPage({ isOnboarded, onOnboardClicked }) {
  const dispatch = useDispatch();
  const { requestingAccess, requestAccessPayload, requestAccessError } =
    useSelector(({ payouts }) => payouts);
  const { Client_Identifier } = getUserDetails()?.accessToken
    ? jwt_decode(getUserDetails()?.accessToken)
    : {};
  const [submitStatus, setSubmitStatus] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleConfirm = (confirmed) => {
    setShowConfirm(false);
    if (confirmed) {
      setSubmitStatus(true);
      dispatch(requestAccess({ merchantId: Client_Identifier }));
    }
  };

  const handleClose = () => {
    setMessage(false);
    dispatch(checkMerchantStatus());
  };

  const handleRequestAccess = () => {
    onOnboardClicked();
    setShowConfirm(true);
  };

  useEffect(() => {
    if (
      !requestingAccess &&
      (requestAccessPayload || requestAccessError) &&
      submitStatus
    ) {
      setSubmitStatus(false);
      if (!requestAccessPayload?.data?.hasError) {
        setDisabled(true);
      }
      setMessage(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestingAccess, requestAccessPayload, submitStatus]);

  return (
    <div className="onboard-wrapper">
      <section className="head-section d-flex sp-mb--24">
        <div
          className="head-section__icon d-flex justify-content-center align-items-center"
          onClick={() => {
            window.location.replace("/services");
          }}
        >
          <i className="ri-arrow-drop-left-line"></i> Payout
        </div>
      </section>
      <section className="card-section sp-pt--24 sp-pb--24 sp-pl--32 sp-pr--32">
        <div className="sp-mb--16">
          <Typography
            typographyVariant="section-title"
            text="Payout works so quick"
          />
        </div>
        <div className="w-sm-100 w-75">
          <p className="content-paragraph">
            Create, and configure disbursement payouts on the BaseOne dashboard
            in 2 minutes. Then, access your custom code for use in your
            application to handle the payout of funds. No coding knowledge is
            required.
          </p>
        </div>
        <div>
          <Button
            className="buton"
            label={requestingAccess ? "Onboarding..." : "Onboard"}
            background="blue"
            disabled={isOnboarded || disabled || requestingAccess}
            size="md"
            onClick={handleRequestAccess}
          />
          <Button
            label={
              <span className="d-flex align-items-center">
                <span>Learn More</span>
                <i className="ri-arrow-right-up-line"></i>
              </span>
            }
            onClick={() =>
              window.open(
                "https://developer.baseone.co/docs/payouts/",
                "_blank"
              )
            }
            background="neutral"
            className="learn-more"
          />
        </div>
        <p className="footnote">
          Note: BaseOne empowers you to onboard Payouts for your users and
          customers.
        </p>
      </section>

      <ConfirmationModal show={showConfirm} onConfirm={handleConfirm} />
      <AlertModal
        show={message}
        data={requestAccessPayload}
        onCancel={handleClose}
      />
    </div>
  );
}

export default OnboardingPage;
