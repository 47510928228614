import { payoutsActionTypes } from "../actions/payoutsActions";
import { REQUEST, FAILURE, SUCCESS } from "./action-type.util";

const INITIAL_STATE = {};

const payoutsReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(payoutsActionTypes.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        checkingMerchantStatus: true,
        merchantStatus: null,
        merchantStatusError: null,
      };
    case SUCCESS(payoutsActionTypes.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        checkingMerchantStatus: false,
        merchantStatus: actions.payload,
        merchantStatusError: null,
      };
    case FAILURE(payoutsActionTypes.CHECK_MERCHANT_STATUS):
      return {
        ...state,
        merchantStatus: null,
        checkingMerchantStatus: false,
        merchantStatusError: actions.payload,
      };

    case REQUEST(payoutsActionTypes.GET_SUPPORTED_COUNTRIES):
      return {
        ...state,
        supportedCountries: null,
        supportedCountriesError: null,
        loadingSupportedCountries: true,
      };
    case SUCCESS(payoutsActionTypes.GET_SUPPORTED_COUNTRIES):
      return {
        ...state,
        loadingSupportedCountries: false,
        supportedCountries: actions?.payload?.data,
      };
    case FAILURE(payoutsActionTypes.GET_SUPPORTED_COUNTRIES):
      return {
        ...state,
        supportedCountries: { responseData: [] },
        supportedCountriesError: actions?.payload,
        loadingSupportedCountries: false,
      };

    case REQUEST(payoutsActionTypes.GET_CURRENCIES):
      return {
        ...state,
        currencies: [],
        currenciesError: null,
        loadingCurrencies: true,
      };
    case SUCCESS(payoutsActionTypes.GET_CURRENCIES):
      return {
        ...state,
        loadingCurrencies: false,
        currencies: actions?.payload?.data?.responseData,
      };
    case FAILURE(payoutsActionTypes.GET_CURRENCIES):
      return {
        ...state,
        currencies: null,
        currenciesError: actions?.payload,
        loadingCurrencies: false,
      };

    case REQUEST(payoutsActionTypes.GET_SUPPORTED_CURRENCIES):
      return {
        ...state,
        supportedCurrencies: [],
        supportedCurrenciesError: null,
        loadingSupportedCurrencies: true,
      };
    case SUCCESS(payoutsActionTypes.GET_SUPPORTED_CURRENCIES):
      return {
        ...state,
        loadingSupportedCurrencies: false,
        supportedCurrenciesError: null,
        supportedCurrencies: actions?.payload?.data?.responseData,
      };
    case FAILURE(payoutsActionTypes.GET_SUPPORTED_CURRENCIES):
      return {
        ...state,
        supportedCurrencies: null,
        supportedCurrenciesError: actions?.payload,
        loadingSupportedCurrencies: true,
      };

    case REQUEST(payoutsActionTypes.GET_SUPPORTED_BANKS):
      return {
        ...state,
        banksPayload: null,
        banksPayloadError: null,
        loadingBanks: true,
      };
    case SUCCESS(payoutsActionTypes.GET_SUPPORTED_BANKS):
      return {
        ...state,
        loadingBanks: false,
        banksPayload: actions?.payload?.data,
      };
    case FAILURE(payoutsActionTypes.GET_SUPPORTED_BANKS):
      return {
        ...state,
        loadingBanks: false,
        banksPayloadError: actions?.payload,
      };

    case REQUEST(payoutsActionTypes.RESOLVE_BANK_ACCOUNT):
      return {
        ...state,
        resolveBankAccountLoading: true,
        resolveBankAccountSuccess: null,
        resolveBankAccountError: null,
      };
    case SUCCESS(payoutsActionTypes.RESOLVE_BANK_ACCOUNT):
      return {
        ...state,
        resolveBankAccountLoading: false,
        resolveBankAccountSuccess: actions.payload.data,
        resolveBankAccountError: null,
      };
    case FAILURE(payoutsActionTypes.RESOLVE_BANK_ACCOUNT):
      return {
        ...state,
        resolveBankAccountLoading: false,
        resolveBankAccountSuccess: null,
        resolveBankAccountError: actions.payload,
      };
      case REQUEST(payoutsActionTypes.WEBHOOK_NOTIFICATION):
      return {
        ...state,
        webhookNotificationSuccess: null,
        webhookNotificationError: null,
        webhookNotificationLoading: true,
      };
    case SUCCESS(payoutsActionTypes.WEBHOOK_NOTIFICATION):
      return {
        ...state,
        webhookNotificationLoading: false,
        webhookNotificationSuccess: actions.payload,
        webhookNotificationError: null,
      };
    case FAILURE(payoutsActionTypes.WEBHOOK_NOTIFICATION):
      return {
        ...state,
        webhookNotificationLoading: false,
        webhookNotificationSuccess: null,
        webhookNotificationError: actions.payload,
      };
    

    case REQUEST(payoutsActionTypes.FETCH_FORM_CONFIG):
      return {
        ...state,
        formConfig: null,
        formConfigError: null,
        fetchingFormConfig: true,
      };
    case SUCCESS(payoutsActionTypes.FETCH_FORM_CONFIG):
      return {
        ...state,
        fetchingFormConfig: false,
        formConfig: actions?.payload?.data?.responseData,
      };
    case FAILURE(payoutsActionTypes.FETCH_FORM_CONFIG):
      return {
        ...state,
        fetchingFormConfig: false,
        formConfigError: actions?.payload,
      };

    case REQUEST(payoutsActionTypes.GET_SUPPORTED_WALLETS):
      return {
        ...state,
        walletsPayload: null,
        walletsPayloadError: null,
        loadingWallets: true,
      };
    case SUCCESS(payoutsActionTypes.GET_SUPPORTED_WALLETS):
      return {
        ...state,
        loadingWallets: false,
        walletsPayload: actions?.payload?.data,
      };
    case FAILURE(payoutsActionTypes.GET_SUPPORTED_WALLETS):
      return {
        ...state,
        loadingWallets: false,
        walletsPayloadError: actions?.payload,
      };

    case REQUEST(payoutsActionTypes.GET_SUPPORTED_MOBILE_PROVIDERS):
      return {
        ...state,
        providersPayload: null,
        providersPayloadError: null,
        loadingProviders: true,
      };
    case SUCCESS(payoutsActionTypes.GET_SUPPORTED_MOBILE_PROVIDERS):
      return {
        ...state,
        loadingProviders: false,
        providersPayload: actions?.payload?.data?.responseData,
      };
    case FAILURE(payoutsActionTypes.GET_SUPPORTED_MOBILE_PROVIDERS):
      return {
        ...state,
        loadingProviders: false,
        providersPayloadError: actions?.payload,
      };

    case REQUEST(payoutsActionTypes.MAKE_TRANSFER):
      return {
        ...state,
        transferPayload: null,
        transferPayloadError: null,
        makingTransfer: true,
      };
    case SUCCESS(payoutsActionTypes.MAKE_TRANSFER):
      return {
        ...state,
        makingTransfer: false,
        transferPayload: actions?.payload?.data,
      };
    case FAILURE(payoutsActionTypes.MAKE_TRANSFER):
      return {
        ...state,
        transferPayloadError: actions?.payload,
        makingTransfer: false,
      };

    case REQUEST(payoutsActionTypes.GET_TRANSACTIONS_HISTORY):
      return {
        ...state,
        loadingTransactionsHistory: true,
        transactionsHistory: null,
        transactionsHistoryError: null,
      };
    case SUCCESS(payoutsActionTypes.GET_TRANSACTIONS_HISTORY):
      return {
        ...state,
        loadingTransactionsHistory: false,
        transactionsHistory: actions?.payload?.data,
        transactionsHistoryError: null,
      };
    case FAILURE(payoutsActionTypes.GET_TRANSACTIONS_HISTORY):
      return {
        ...state,
        transactionsHistory: null,
        transactionsHistoryError: actions?.payload,
        loadingTransactionsHistory: false,
      };

    case REQUEST(payoutsActionTypes.GET_SETTINGS_DATA):
      return {
        ...state,
        loadingSettingsData: true,
        settingsData: null,
        settingsDataError: null,
      };
    case SUCCESS(payoutsActionTypes.GET_SETTINGS_DATA):
      return {
        ...state,
        loadingSettingsData: false,
        settingsData: actions?.payload?.data,
        settingsDataError: null,
      };
    case FAILURE(payoutsActionTypes.GET_SETTINGS_DATA):
      return {
        ...state,
        loadingSettingsData: false,
        settingsData: null,
        settingsDataError: null,
      };

    case REQUEST(payoutsActionTypes.UPDATE_SETTINGS_DATA):
      return {
        ...state,
        updatingSettingsData: true,
        updateSettingsDataPayload: null,
        updateSettingsDataError: null,
      };
    case SUCCESS(payoutsActionTypes.UPDATE_SETTINGS_DATA):
      return {
        ...state,
        updatingSettingsData: false,
        updateSettingsDataPayload: actions?.payload?.data,
        updateSettingsDataError: null,
      };
    case FAILURE(payoutsActionTypes.UPDATE_SETTINGS_DATA):
      return {
        ...state,
        updateSettingsDataError: actions?.payload,
        updateSettingsDataPayload: null,
        updatingSettingsData: false,
      };

    case REQUEST(payoutsActionTypes.GET_CHART_DATA):
      return {
        ...state,
        loadingChartData: true,
        chartDataPayload: null,
        chartDataPayloadError: null,
      };
    case SUCCESS(payoutsActionTypes.GET_CHART_DATA):
      return {
        ...state,
        loadingChartData: false,
        chartDataPayload: actions?.payload?.data,
        chartDataPayloadError: null,
      };
    case FAILURE(payoutsActionTypes.GET_CHART_DATA):
      return {
        ...state,
        chartDataPayloadError: actions?.payload,
        chartDataPayload: null,
        loadingChartData: false,
      };

    case REQUEST(payoutsActionTypes.TOGGLE_STATUS):
      return {
        ...state,
        switchingStatus: true,
        switchStatusSuccess: null,
        switchStatusError: null,
      };
    case SUCCESS(payoutsActionTypes.TOGGLE_STATUS):
      return {
        ...state,
        switchingStatus: false,
        switchStatusSuccess: actions?.payload,
        switchStatusError: null,
      };
    case FAILURE(payoutsActionTypes.TOGGLE_STATUS):
      return {
        ...state,
        switchStatusSuccess: null,
        switchStatusError: actions?.payload,
        switchingStatus: false,
      };

    case REQUEST(payoutsActionTypes.REQUEST_ACCESS):
      return {
        ...state,
        requestingAccess: true,
        requestAccessPayload: null,
        requestAccessError: null,
      };
    case SUCCESS(payoutsActionTypes.REQUEST_ACCESS):
      return {
        ...state,
        requestingAccess: false,
        requestAccessPayload: actions?.payload,
        requestAccessError: null,
      };
    case FAILURE(payoutsActionTypes.REQUEST_ACCESS):
      return {
        ...state,
        requestAccessPayload: null,
        requestAccessError: actions?.payload,
        requestingAccess: false,
      };

    default:
      return { ...state };
  }
};

export default payoutsReducer;
