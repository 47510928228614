import { userDetails } from "./user.service";

// https://afservicemanagerclienttest.azurewebsites.net
export const baseUrl =
  process.env.NODE_ENV === "development"
    ? "https://vgnpcdisagrservicetest.azurewebsites.net/api/v1"
    : `${window.env?.payoutBaseApiUrl}/api/v1`;
export const baseUrlV2 =
  process.env.NODE_ENV === "development"
    ? "https://vgnpcdisagrservicetest.azurewebsites.net/v1"
    : `${window.env?.payoutBaseApiUrl}/v1`;
export const serviceManager =
  process.env.NODE_ENV === "development"
    ? "https://afservicemanagerclienttest.azurewebsites.net/api/v1"
    : `${window.env?.baseApiUrl}/api/v1`;
export const accountServiceApiUrl =
  process.env.NODE_ENV === "development"
    ? "https://accountserviceaggregator-apitest.azurewebsites.net/api/v1"
    : `${window.env?.accountServiceApiUrl}/api/v1`;

export const config = () => {
  if (userDetails()) {
    const bearerToken = `Bearer ${userDetails().accessToken}`;
    return { headers: { Authorization: bearerToken } };
  }
  return null;
};

// Get List of Wallets TEST: /corporateWallet/wallets/client

// Get List of Wallets STAGING: https://accountserviceaggregator-apistag.azurewebsites.net/api/v1/corporateWallet/wallets/client

// Get List of Wallets PROD: https://accountserviceaggregator-apiprod.azurewebsites.net/api/v1/corporateWallet/wallets/client
