import React from "react";
import { Route, Switch } from "react-router";
import URLS from "helpers/urls";
import Notification from "shared/notifications/notifications";
import ManagerPage from "pages/ManagerPage/ManagerPage";

const App = ({ url }) => {

  return (
    <div className="container-fluid pb-5 app-wrap">
      <Notification />
      <Switch>
        <Route path={URLS.Home} component={ManagerPage} />
      </Switch>
    </div>
  );
};

export default App;
