import React, { useRef } from "react";
import * as Yup from "yup";
import Button from "vgg-ui-react/dist/components/Core/Button";
import Select from "react-select";
import SelectControl from "components/selectControl/SelectControl";
import { fetchSupportedBanks } from "redux/actions/payoutsActions";
import Input from "react-phone-number-input/react-hook-form-input";
import { isValidPhoneNumber } from "react-phone-number-input/mobile";
import { getInvalidAccountMsg } from "helpers/getInvalidAccountMsg";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

const validationSchema = (channel) =>
  Yup.object().shape({
    BeneficiaryFirstName: Yup.string().when(["country"], {
      is: (country) => country === "KEN" && channel === 1,
      then: Yup.string().required("Beneficiary First Name is required"),
    }),
    BeneficiaryLastName: Yup.string().when(["country"], {
      is: (country) => country === "KEN" && channel === 1,
      then: Yup.string().required("Beneficiary Last Name is required"),
    }),
    debitWallet: Yup.string().required("Wallet is required!"),
    country: Yup.string().required("Country is required!"),
    destinationAmount: Yup.string()
      .required("Amount is required!")
      .matches(/^[0-9]+$/, "Input must be a numeric value")
      .test("greaterThanZero", "Value must be greater than 0", (value) => {
        const numericValue = parseFloat(value);
        return !isNaN(numericValue) && numericValue > 0;
      })
      .when(["debitWallet"], {
        is: (debitWallet) => channel === 2 && debitWallet === "KES",
        then: Yup.string().test(
          "is-greater-than-10",
          "Minimum value for KES is 10 KES",
          (value) => {
            return parseInt(value, 10) >= 10;
          }
        ),
      })
      .when(["debitWallet"], {
        is: (debitWallet) => channel === 2 && debitWallet === "NGN",
        then: Yup.string().test(
          "is-greater-than-10",
          "Minimum value for NGN is 10 NGN",
          (value) => {
            return parseInt(value, 10) >= 10;
          }
        ),
      })
      .when(["debitWallet"], {
        is: (debitWallet) => channel === 1 && debitWallet === "KES",
        then: Yup.string().test(
          "is-greater-than-950,000",
          "Maximum value for KES is 950,000",
          (value) => {
            return parseInt(value) <= 950000;
          }
        )
      })
        .when(["debitWallet"], {
          is: (debitWallet) => channel === 2 && debitWallet === "KES",
          then: Yup.string().test(
            "is-greater-than-150,000",
            "Maximum value for KES is 150,000",
            (value) => {
              return parseInt(value) <= 150000;
            }
          ),
      }),
    institutionCode: Yup.string().required(
      channel === 1 ? "Bank is required!" : "Mobile operator is required!"
    ),
    destinationAccount: Yup.string()
      .required(
        channel === 1
          ? "Account Number is required!"
          : "Phone Number is required!"
      )
      .test(
        "accountValid",
        channel === 1
          ? "Not a valid account number for selected country"
          : "Not a valid phone number for selected country",
        (value, { createError, parent }) => {
          if (channel === 1) {
            const err = getInvalidAccountMsg(parent.country, value);
            if (err.message) {
              return createError({
                message: err.message,
                path: "destinationAccount",
              });
            }
            return err.valid;
          } else {
            return value;
          }
        }
      ),
  });

const defaultValues = {
  debitWallet: "",
  country: "",
  destinationAmount: "",
  institutionCode: "",
  destinationAccount: "",
  narration: "",
};

const TransferForm = ({
  submitForm,
  sending,
  walletOptions,
  bankOptions,
  countryOptions,
  loadingWallets,
  onCountrySelect,
  onBackClick,
  channel,
  dispatch,
  resolveAcct,
  setFetchedBank,
  fetchingBanks,
  handleResolveAccount,
  onChange,
}) => {
  const selectInputRef = useRef();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema(channel)),
    defaultValues,
  });

  const getAccountValidityStatus = () => {
    return (
      !resolveAcct?.loading &&
      (isValidPhoneNumber(getValues()?.destinationAccount || "") ||
        getInvalidAccountMsg(
          getValues()?.country,
          getValues()?.destinationAccount
        )?.valid) &&
      getValues().institutionCode &&
      getValues().country &&
      resolveAcct?.payload?.includes("nvalid")
    );
  };

  return (
    <form className="fade_in" onSubmit={handleSubmit(submitForm)} data-testid="form">
      <div className="row">
        <div className="col-12 mb-2">
          <label htmlFor="debitWallet" className="form-label">
            Wallet
          </label>

          <Controller
            control={control}
            name="debitWallet"
            id="debitWallet"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                {...register("debitWallet", { required: true })}
                placeholder=""
                onBlur={onBlur}
                inputId="debitWallet"
                isLoading={loadingWallets}
                onChange={(e) => onChange({ target: e || "" })}
                inputRef={ref}
                controlClassName={`form-control ${
                  errors?.debitWallet?.message ? "is-invalid" : ""
                } ps-0 pt-0 pb-0`}
                controlErrorMsg={errors?.debitWallet?.message}
                components={{
                  IndicatorSeparator: () => null,
                  Control: SelectControl,
                }}
                defaultValue={value}
                options={walletOptions}
              />
            )}
          />
        </div>
        <div className="col-12 mb-2">
          <label htmlFor="country" className="form-label">
            Country
          </label>
          <Controller
            control={control}
            name="country"
            id="country"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                placeholder=""
                isClearable
                isSearchable
                inputId="country"
                {...register("country", { required: true })}
                onBlur={onBlur}
                onChange={(e) => {
                  if (selectInputRef?.current) {
                    setValue("institutionCode", "");
                    selectInputRef.current.clearValue();
                    selectInputRef.current.value = "";
                  }

                  if (e?.value) {
                    onCountrySelect && onCountrySelect(e?.value);
                    dispatch(
                      fetchSupportedBanks({ channel, country: e?.value })
                    );
                  }
                  setValue("destinationAccount", "");
                  setFetchedBank(true);
                  const val = e ? { target: e } : "";
                  return onChange(val);
                }}
                inputRef={ref}
                controlClassName={`form-control ${
                  errors?.country?.message ? "is-invalid" : ""
                } ps-0 pt-0 pb-0`}
                controlErrorMsg={errors?.country?.message}
                components={{
                  IndicatorSeparator: () => null,
                  Control: SelectControl,
                }}
                defaultValue={value}
                options={countryOptions}
              />
            )}
          />
        </div>

        {getValues()?.country === "KEN" && channel === 1 && (
          <>
            <div className="col-12 mb-2">
              <label htmlFor="BeneficiaryFirstName" className="form-label">
                Beneficiary First Name
              </label>
              <input
                {...register("BeneficiaryFirstName", { required: true })}
                type="text"
                id="BeneficiaryFirstName"
                className={`form-control ${
                  errors?.BeneficiaryFirstName?.message ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">
                {errors?.BeneficiaryFirstName?.message}
              </div>
            </div>
            <div className="col-12 mb-2">
              <label htmlFor="BeneficiaryLastName" className="form-label">
                Beneficiary Last Name
              </label>
              <input
                {...register("BeneficiaryLastName", { required: true })}
                type="text"
                id="BeneficiaryLastName"
                className={`form-control ${
                  errors?.BeneficiaryLastName?.message ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">
                {errors?.BeneficiaryLastName?.message}
              </div>
            </div>
          </>
        )}

        <div className="col-12 mb-2">
          <label htmlFor="institutionCode" className="form-label">
            {channel === 1 ? "Bank" : "Mobile Operator"}
          </label>
          {false ? (
            <>
              {" "}
              <input
                {...register("institutionCode", { required: true })}
                type="text"
                id="institutionCode"
                className={`form-control ${
                  errors?.institutionCode?.message ? "is-invalid" : ""
                }`}
                onKeyUp={(e) => {
                  channel === 1 &&
                    handleResolveAccount(
                      getValues().destinationAccount,
                      "institutionCode",
                      getValues().country,
                      e.target.value
                    );
                }}
              />
              <div className="invalid-feedback">
                {errors?.institutionCode?.message}
              </div>
            </>
          ) : (
            <Controller
              control={control}
              name="institutionCode"
              id="institutionCode"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  {...register("institutionCode", { required: true })}
                  isClearable
                  isSearchable
                  ref={selectInputRef}
                  inputId="institutionCode"
                  isLoading={fetchingBanks}
                  placeholder=""
                  onBlur={onBlur}
                  onChange={(e) => {
                    channel === 1 &&
                      handleResolveAccount(
                        getValues().destinationAccount,
                        "institutionCode",
                        getValues().country,
                        e?.value || ""
                      );
                    const val = e ? { target: e } : "";
                    return onChange(val);
                  }}
                  inputRef={ref}
                  controlClassName={`form-control ${
                    errors?.institutionCode?.message ? "is-invalid" : ""
                  } ps-0 pt-0 pb-0`}
                  controlErrorMsg={errors?.institutionCode?.message}
                  components={{
                    IndicatorSeparator: () => null,
                    Control: SelectControl,
                  }}
                  defaultValue={value}
                  options={bankOptions}
                  noOptionsMessage={() =>
                    getValues().country
                      ? "No data for selected country"
                      : "Select country to see data"
                  }
                />
              )}
            />
          )}
        </div>

        <div className="col-12 mb-2">
          <label htmlFor="destinationAmount" className="form-label">
            Amount
          </label>
          <input
            {...register("destinationAmount", { required: true })}
            type="text"
            id="destinationAmount"
            className={`form-control ${
              errors?.destinationAmount?.message ? "is-invalid" : ""
            }`}
          />
          <div className="invalid-feedback">
            {errors?.destinationAmount?.message}
          </div>
        </div>

        <div
          className={`col-12 ${
            errors?.destinationAccount?.message ||
            errors?.destinationAccount?.message
              ? "mb-2"
              : ""
          }`}
        >
          <label htmlFor="destinationAccount" className="form-label">
            {channel === 1 ? "Account Number" : "Phone Number"}
          </label>
          {channel === 1 ? (
            <input
              type="text"
              {...register("destinationAccount")}
              id="destinationAccount"
              className={`form-control ${
                errors?.destinationAccount?.message ? "is-invalid" : ""
              }`}
              onKeyUp={(e) => {
                getValues()?.country !== "KEN" &&
                  channel === 1 &&
                  handleResolveAccount(
                    e.target.value,
                    "destinationAccount",
                    getValues().country,
                    getValues().institutionCode
                  );
              }}
            />
          ) : (
            <Controller
              control={control}
              name="destinationAccount"
              id="destinationAccount"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  control={control}
                  country={
                    typeof getValues()?.country === "string"
                      ? getValues()?.country?.slice(0, 2)?.toUpperCase()
                      : ""
                  }
                  placeholder=""
                  className={`form-control ${
                    errors?.destinationAccount?.message ? "is-invalid" : ""
                  }`}
                  id="destinationAccount"
                  name="destinationAccount"
                  value={getValues().destinationAccount}
                  onChange={(value) => {
                    channel === 1 &&
                      handleResolveAccount(
                        value,
                        "destinationAccount",
                        getValues().country,
                        getValues().institutionCode
                      );
                    return onChange({ target: { value: value || "" } });
                  }}
                />
              )}
            />
          )}
          <div className="invalid-feedback">
            {errors?.destinationAccount?.message}
          </div>
        </div>

        {!errors?.destinationAccount?.message ? (
          <p className="fw-bold text-sm">
            {resolveAcct?.loading ? (
              "resolving account..."
            ) : (isValidPhoneNumber(getValues()?.destinationAccount || "") ||
                getInvalidAccountMsg(
                  getValues()?.country,
                  getValues()?.destinationAccount
                )?.valid) &&
              getValues().institutionCode &&
              getValues().country ? (
              <span
                className={
                  resolveAcct?.payload?.includes("nvalid")
                    ? "text-danger"
                    : "text-sucess"
                }
              >
                {resolveAcct.payload}
              </span>
            ) : undefined}
          </p>
        ) : undefined}

        <div className="col-12 mb-2">
          <label htmlFor="narration" className="form-label">
            Description
          </label>
          <textarea
            {...register("narration")}
            rows="6"
            className={`form-control ${
              errors?.narration?.message ? "is-invalid" : ""
            }`}
            id="narration"
          />
          <div className="invalid-feedback">{errors?.narration?.message}</div>
        </div>
      </div>
      <div className="row fade_in pt-2">
        <div className="col-12">
          <Button
            type="submit"
            className="w-100"
            label={sending ? "Transferring..." : "Transfer"}
            background="blue"
            size="md"
            style={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
            }}
            disabled={sending || getAccountValidityStatus()}
          />
        </div>
        <div className="col-12">
          <Button
            className="w-100"
            label="Go Back"
            background="neutral"
            variant="ghost"
            color="blue"
            size="md"
            style={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "16px",
              letterSpacing: "0.4px",
            }}
            disabled={sending}
            onClick={onBackClick}
          />
        </div>
      </div>
    </form>
  );
};

export default TransferForm;
