import { format } from "date-fns";
import { getCurrencyValue } from "helpers/currencyMarkup";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Card } from "reactstrap";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import Typography from "vgg-ui-react/dist/components/Core/Typography";

import "./barchartOverview.scss";
import { Lozenge } from "vgg-ui-react/dist/components/Core/Lozenge";
import { Badge } from "vgg-ui-react/dist/components/Core/Badge";
import { Tag } from "vgg-ui-react/dist/components/Core/Tag";
import { Button } from "vgg-ui-react/dist/components/Core/Button";

export const options = {
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat("en-ng", {
              style: "currency",
              currency: "NGN",
              currencyDisplay: "symbol",
            }).format(context.parsed.y);
          }
          return label;
        },
      },
    },
    legend: {
      display: false,
      align: "start",
    },
    title: {
      display: false,
      text: "",
      align: "start",
    },
  },
};

const BarChartOverview = ({
  loading,
  dataSource,
  totalAmount,
  onCurrencyTabClick,
  currencies = [],
  selectedCurrency,
}) => {
  const [data, setData] = useState(null);

  const handleTabItemClick = (data) => {
    onCurrencyTabClick && onCurrencyTabClick(data);
  };

  useEffect(() => {
    if (dataSource && dataSource.length) {
      const d = dataSource.map((v) => v.amount);
      setData({
        labels: dataSource.map(
          (v) =>
            v.transactionDate && format(new Date(v.transactionDate), "dd MMM")
        ),
        datasets: [
          {
            label: "",
            data: d,
            hoverBackgroundColor: "#175FFF",
            backgroundColor: "rgba(23, 95, 255, 0.2)",
            maxBarThickness: 25,
          },
        ],
      });
    } else {
      setData(null);
    }
  }, [dataSource]);

  return (
    <Card>
      <div className="sp-p--16 sp-mb--16 w-100 d-flex align-items-center justify-content-between bb">
        <Typography typographyVariant="section-title" text="Total Spent" />
        <div className="d-flex align-items-center justify-content-end gap-2">
          {currencies.map((currency) => (
            <span
              className="p-0 bg-transparent"
              key={currency}
              role="button"
              onClick={() => handleTabItemClick(currency)}
            >
              <Badge
                className={`currencies ${
                  currency === selectedCurrency ? "active" : ""
                }`}
                content={currency}
              />
            </span>
          ))}
        </div>
      </div>
      <div className="sp-p--24 sp-mb--16">
        <Typography
          typographyVariant="disp-heading"
          text={getCurrencyValue(selectedCurrency, totalAmount)}
        />
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center text-center h-36">
          Loading...
        </div>
      ) : (
        <>
          {data ? (
            <Bar options={options} data={data} height="80vh" />
          ) : (
            <div className="d-flex align-items-center justify-content-center wrap-nodata">
              No chart data
            </div>
          )}
        </>
      )}
    </Card>
  );
};

export default BarChartOverview;
