import BarChartOverview from "./BarChartOverview/BarChartOverview";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _debounce from "lodash.debounce";
import {
  fetchTransactionsHistory,
  fetchChartData,
  fetchSupportedCurrencies,
} from "redux/actions/payoutsActions";
import Button from "vgg-ui-react/dist/components/Core/Button";
import DateRangePicker from "react-daterange-picker";

import swal from "@sweetalert/with-react";
import TransactionsHistory from "./TransactionsHistory/TransactionsHistory";
import "react-daterange-picker/dist/css/react-calendar.css";
import PayoutModal from "./PayoutModal/PayoutModal";
import "./overview.scss";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";

const TRANSACTION_STATUSES = [
  "All",
  "Success",
  "Failed",
  "Processing",
  "Pending",
];

const Overview = () => {
  const dispatch = useDispatch();
  const {
    chartDataPayload,
    loadingChartData,
    loadingTransactionsHistory,
    transactionsHistory,
    supportedCurrencies,
  } = useSelector(({ payouts }) => payouts);

  const [showModal, setShowModal] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [activeCurrency, setActiveCurrency] = useState("NGN");
  const [transactionStatus, setTransactionStatus] = useState("All");
  const [remount, setRemount] = useState(false);
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(fetchTransactionsHistory({ ...page }));
    dispatch(fetchSupportedCurrencies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    dispatch(
      fetchChartData({
        currency: activeCurrency,
        startDate: dateRange ? dateRange?.start?.format("YYYY-MM-DD") : null,
        endDate: dateRange ? dateRange?.end?.format("YYYY-MM-DD") : null,
      })
    );
    dispatch(
      fetchTransactionsHistory({
        pageNumber: 1,
        pageSize: 10,
        currency: activeCurrency,
        startDate: dateRange ? dateRange?.start?.format("YYYY-MM-DD") : null,
        endDate: dateRange ? dateRange?.end?.format("YYYY-MM-DD") : null,
        status: transactionStatus,
      })
    );
    if (remount) {
      setRemount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, activeCurrency]);
  useEffect(() => {
    dispatch(
      fetchTransactionsHistory({
        pageNumber: 1,
        pageSize: 10,
        currency: activeCurrency,
        startDate: dateRange ? dateRange?.start?.format("YYYY-MM-DD") : null,
        endDate: dateRange ? dateRange?.end?.format("YYYY-MM-DD") : null,
        status: transactionStatus,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionStatus]);

  const onSelect = (value) => {
    const start = value.start;
    const end = value.end;
    const dateCount = end.diff(start, "days") + 1;
    if (dateCount > 30) {
      swal({
        text: "Kindly select range to be at most 30 days.",
        icon: "info",
        button: "Okay",
      });
    } else {
      setDateRange(value);
    }
  };

  const onClearSelection = () => {
    setDateRange(null);
  };

  const renderContent = (range) => {
    return (
      <>
        {!range
          ? "Date"
          : `${range?.start?.format("LL")} - ${range?.end?.format("LL")}`}
      </>
    );
  };

  const onSearchTransaction = _debounce((event) => {
    dispatch(
      fetchTransactionsHistory({
        ...page,
        currency: activeCurrency,
        startDate: dateRange ? dateRange?.start?.format("YYYY-MM-DD") : null,
        endDate: dateRange ? dateRange?.end?.format("YYYY-MM-DD") : null,
        status: transactionStatus,
        search: event.target.value,
      })
    );
  }, 1000);

  const handleSelectTransactionStatus = (val) => {
    // if (val === "All") {
    //   setTransactionStatus([]);
    // } else {
    //   setTransactionStatus((prev) => {
    //     if (prev.includes(val)) return prev.filter((v) => v !== val);
    //     return [...prev, val];
    //   });
    // }
    setTransactionStatus(val);
  };

  return (
    <div className="fade_in h-100">
      <div className="top-position">
        <div className="d-flex align-items-center justify-content-end">
          <FilterDropdown buttonContent={renderContent(dateRange)}>
            {!remount && (
              <DateRangePicker
                numberOfCalendars={2}
                value={dateRange}
                onSelect={onSelect}
                className="payout-reports"
              />
            )}
            <div className="py-2 px-3">
              {dateRange && (
                <Button
                  label="Clear Selection"
                  background="blue"
                  className="w-100"
                  onClick={() => {
                    setRemount(true);
                    onClearSelection();
                  }}
                />
              )}
            </div>
          </FilterDropdown>
          <Button
            className="ms-3 initiate-payout"
            label="Initiate Payout"
            background="blue"
            size="sm"
            onClick={() => setShowModal(true)}
          />
        </div>
      </div>

      <div className="sp-pt--12">
        <BarChartOverview
          currencies={supportedCurrencies?.items?.map(
            (currency) => currency?.currencyCode
          )}
          renderContent={renderContent}
          selectedCurrency={activeCurrency}
          loading={loadingChartData}
          dataSource={chartDataPayload?.responseData?.transactions}
          totalAmount={chartDataPayload?.responseData?.totalAmount}
          onCurrencyTabClick={(data) => setActiveCurrency(data)}
        />
      </div>
      <div className="sp-pt--32">
        <TransactionsHistory
          payload={transactionsHistory}
          loading={loadingTransactionsHistory}
          page={page}
          onPageChange={(page) =>
            setPage((prev) => ({ ...prev, pageNumber: page }))
          }
          handleSearch={onSearchTransaction}
          onStatusSelect={handleSelectTransactionStatus}
          statuses={TRANSACTION_STATUSES}
          selectedStatus={transactionStatus}
        />
      </div>

      <PayoutModal
        show={showModal}
        toggleModal={() => setShowModal(!showModal)}
      />
    </div>
  );
};

export default Overview;
